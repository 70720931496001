import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { EmailProvider } from './EmailContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './routes/Login';
import { ProtectedRoute } from './routes/ProtectedRoute';
import EmailList from './routes/EmailList';
import LandingPage from './routes/LandingPage';
import Pricing from './components/Pricing';
import Signup from './routes/Signup';
import Thanks from './routes/Thanks';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>,
  },
  {
    path: "/signup",
    element: <Signup/>,
  },
  {
    path: "/thanks",
    element: <Thanks/>,
  },
  // {
  //   path: "/login",
  //   element: <Login/>,
  // },
  // {
  //   path: "/",
  //   element: <ProtectedRoute><EmailList/></ProtectedRoute>,
  // },
  // {
  //   path: "/emails",
  //   element: <ProtectedRoute><EmailList/></ProtectedRoute>,
  // },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <EmailProvider>
      <RouterProvider router={router} />
    </EmailProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
