import * as React from 'react';
import { AppBar, Button, Container, Grid, Stack, TextField, Toolbar, Typography, alpha } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Hero from '../components/Hero';
import { DemoEmailView } from '../components/DemoView';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

function appBarLabel(label: string) {
  return (
    <Toolbar variant='dense' style={{ boxShadow: "" }}>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}
export default function Signup() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const plan = searchParams.get("plan");

  const navigate = useNavigate();
  const regEx = new RegExp(/^\S+@\S+\.\S+$/);

  return (
    <Box
      id="hero"
    >
      <Container
        style={{paddingTop: 25,}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >

        <Box
          id="image"
          style={{ marginTop: 40, height: 350 }}
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            padding: 5,
            justifyContent: "center",
            height: { xs: 500, sm: 400 },
            width: '80%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}>

          <Stack style={{ justifyContent: "center", padding: 10, marginTop: 70, }} spacing={2}>
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(1.5rem, 10vw, 1.5rem)',
              }}
            >
              You have caught us before we are completely ready!
            </Typography>
            <Typography
              textAlign="center"
              color="text.secondary"
              sx={{ alignSelf: 'center', width: { sm: '100%', md: '100%' } }}
            >
              Please join the waitlist for updates
            </Typography>
          </Stack>
            
            <Stack
              style={{ justifyContent: "center"}}
              direction={{ xs: 'column', sm: 'row' }}
              alignSelf="center"
              spacing={1}
              sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
            >
              <TextField
                style={{ width: "auto" }}
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="Your email address"
                error={error}
                helperText={ error ? "Please enter a valid email address :)" : ""}
                inputProps={{
                  autoComplete: 'off',
                  'aria-label': 'Enter your email address',
                }}
                onChange={(text) => {
                  const add = text.target.value;
                  if(!add.match(regEx)) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                  setEmail(text.target.value)
                }}
              />
              <Button disabled={error || email.length == 0} variant="contained" color="primary" style={{ background:  error || email.length ===0  ? "grey" : "black", }} onClick={async () => {
                if(!error) {
                  await fetch("https://europe-west1-spectext-2e9fb.cloudfunctions.net/addToWaitingList", {
                    method: "POST",
                    mode: "no-cors",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, plan: plan || "" })
                  });
                  console.log("SENT", email, plan)
                  navigate("/thanks")
                }

              }}>
                Join Waitlist
              </Button>
          </Stack>
        </Box>
          
      </Container>
    </Box>
  );
}