

import { Input, Stack } from "@mui/material"



export const DemoEmailView = ({ text, header }) => {

  return (
      
      <Stack style={{ padding: 10, marginTop: 25 }} direction={{ xs: 'column', sm: "column" }}>

        <div style={{ height: 400, border: "1px solid #b3b3b3", background: "white", borderRadius: 8 }}>
        <h2 style={{textAlign: "center"}}>{header}</h2>
          <div style={{ margin: 15, }}>
            <form>
              <Stack spacing={1}>
                <Input placeholder="From: abusive-coparent@gmail.com" style={{ pointerEvents: "none" }}/>
                <Input placeholder="Subject: Your sister!!!!" style={{ pointerEvents: "none" }}/>

                <textarea 
                  readOnly
                  style={{ height: 170, marginTop: 25, border: "1px solid #b3b3b3", padding: 10, pointerEvents: "none", resize: "none", fontSize: "clamp(9px, 1vw, 11px)", borderRadius: 8 }} 
                  value={text}>
                </textarea>
              </Stack>
            </form>

              
          </div>

        </div>
      </Stack>
  )
}