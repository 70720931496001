import React, {createContext, useEffect, useState} from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, limit, query, orderBy, } from 'firebase/firestore/lite';

import {Email, sendEmail} from './services/email.service';

const firebaseConfig = {
  apiKey: "AIzaSyD1IVXsZgNpsovn2g14cS-V4umcvbTppSA",
  authDomain: "spectext-2e9fb.firebaseapp.com",
  projectId: "spectext-2e9fb",
  storageBucket: "spectext-2e9fb.appspot.com",
  messagingSenderId: "409352540121",
  appId: "1:409352540121:web:62a45cf621dda1186793c8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export type EmailContextType = {
  emails: any[];
  sendEmail: (email: Email) => Promise<any>;
  getEmails: () => void;
};

export const EmailContext = createContext<EmailContextType>({
  emails: [],
  sendEmail: (email: Email) => Promise.resolve(),
  getEmails: () => {}
});

// const q = query(citiesRef, orderBy("name"), limit(3));
export const EmailProvider = ({children}) => {
  const [emails, setEmails] = useState<any[]>([]);

  const getEmails = async () => {
    const ux = localStorage.getItem("user");
    const user = ux ? JSON.parse(ux) : null;
    if(user) {
      console.log('Getting emails for: ', user);
      const emailsRef = collection(db, user.uid);
      const results = await getDocs(query(emailsRef, orderBy('email.date', 'desc'), limit(10)))
      console.log('got emails:', results.size);
      setEmails(
        results.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })),
      );
    } else {
      console.log("no user found")
    }

  };

  return (
    <EmailContext.Provider value={{ emails, getEmails, sendEmail }}>
      {children}
    </EmailContext.Provider>
  );
};
