import * as React from 'react';
import { AppBar, Button, Container, Grid, Stack, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Hero from '../components/Hero';

const subText = "Coparentmail is an email provider that utilises AI to sanitise and summarise the emails you receive, designed specially for co-parents who rely on emails to communicate but regularly receive abusive and long-winded emails from the other co-parent.\n"+
                "Create an account and be less anxious before opening an email. Have you ever had a situation where you just want a confirmation on if they are picking up your child after school but instead you get two paragraphs about stuff you are just not interested in? Coparentmail can help..."

export default function Story() {
  return (
    <Box>
      <Container
        style={{paddingTop: 15, }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography
            variant="h4"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 2.5rem)',
              paddingBottom: 5
            }}
          >
           How it works
        </Typography>

        <Grid container columns={{ xs: 1, sm: 3, md: 3 }}>
          <Grid item xs={2} sm={1}>
            <div style={{ margin: 10, textAlign: "center",}}>
              <Typography variant="h5" component="div" style={{ marginBottom: 10, }}>
                Dedicated Account
              </Typography>
              <Stack direction="column" spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="body2">
                      Coparentmail is an email provider designed specially for co-parents who rely on emails to communicate but regularly receive abusive and long-winded emails from the other co-parent
                  </Typography>
                </Stack>
              </Stack>             
            </div>
          </Grid>
          <Grid item xs={2} sm={1}>
            <div style={{ margin: 10, textAlign: "center", }}>
              <Typography variant="h5" component="div" style={{ marginBottom: 10, }}>
                Utilises AI
              </Typography>
              <Stack direction="column" spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="body2">
                    The emails you receive are preprocessed by AI which removes expletives, abusive tone and summarises the email content
                  </Typography>
                </Stack>
              </Stack>             
            </div>          
          </Grid>
          <Grid item xs={2} sm={1}>
            <div style={{ margin: 10, textAlign: "center", }}>
              <Typography variant="h5" component="div" style={{ marginBottom: 10, }}>
                Need to know
              </Typography>
              <Stack direction="column" spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="body2">
                    Have you ever had a situation where you just want a confirmation on if they are picking up your child after school but instead you get two paragraphs about stuff you are just not interested in? Coparentmail can help...
                  </Typography>
                </Stack>
              </Stack>             
            </div>        
          </Grid>
        </Grid>

        <Typography
            variant="h4"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 2.5rem)',
            }}
          >
           
        </Typography>

      </Container>
    </Box>
  );
}