import * as React from 'react';
import { AppBar, Button, Stack, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Hero from '../components/Hero';
import Story from '../components/Story';
import Pricing from '../components/Pricing';

function appBarLabel(label: string) {
  return (
    <Toolbar variant='dense' style={{ boxShadow: "" }}>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
        {/* <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          {["Pricing"].map((page) => (
            <Button
              key={page}
              sx={{ my: 2, color: 'black', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box> */}
    </Toolbar>
  );
}



export default function LandingPage() {
  return (
    <Box>
      <AppBar position="static" color="transparent" style={{ boxShadow: "none"}} >
          {appBarLabel("COPARENTMAIL")}
      </AppBar>
      <CssBaseline />
      <Stack>
        <Hero />
        <Story/>
        <Pricing/>
      </Stack>
      
    </Box>
  );
}