import * as React from 'react';
import { AppBar, Button, Container, Grid, PaletteMode, Stack, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, alpha, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


export default function Pricing() {

  const navigate = useNavigate();

  return (

    <Box id="pricingBox">
      <Container
        style={{paddingTop: 5,}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
        <Typography
            variant="h4"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 2.5rem)',
              paddingBottom: 5
            }}
            id="pricing"
          >
            Pricing Plans
          </Typography>
        </Stack>

          <Grid container columns={{ xs: 1, sm: 2, md: 2 }} style={{width: "60%"}} spacing={2}>
            <Grid item xs={2} sm={1}>
              <Box
                id="image"
                style={{ marginTop: 30, height: "100%" }}
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: 'center',
                  height: { xs: 200, sm: 600 },
                  width: '100%',
                  backgroundImage:
                    theme.palette.mode === 'light'
                      ? 'url("/static/images/templates/templates-images/hero-light.png")'
                      : 'url("/static/images/templates/templates-images/hero-dark.png")',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  outline: '1px solid',
                  outlineColor:
                    theme.palette.mode === 'light'
                      ? alpha('#BFCCD9', 0.5)
                      : alpha('#9CCCFC', 0.1),
                  boxShadow:
                    theme.palette.mode === 'light'
                      ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                })}>

                <div style={{ margin: 10, textAlign: "center", padding: 15,  }}>
                  <Typography variant="h5" component="div" style={{ marginBottom: 10, }}>
                    £9.99 <Typography color="text.secondary">per month</Typography>
                  </Typography>

                  <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem/>}>
                    <Button size="small" variant="contained" style={{ background: "black", color: "white" }} onClick={() => navigate("signup?plan=a")}>Start free trial</Button>

                    <Stack spacing={2}>
                      <Typography variant="body2">
                        Email account with AI filter
                      </Typography>
                      <Typography variant="body2">
                        Important dates and times extraction
                      </Typography>
                      <Typography variant="body2">
                        Unlimited emails
                      </Typography>
                    </Stack>
                  </Stack>             
                </div>
              </Box>
            </Grid>

            <Grid item xs={2} sm={1} >
              <Box
                id="image"
                style={{ marginTop: 30, height: "100%" }}
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: 'center',
                  height: { xs: 200, sm: 600 },
                  width: '100%',
                  backgroundImage:
                    theme.palette.mode === 'light'
                      ? 'url("/static/images/templates/templates-images/hero-light.png")'
                      : 'url("/static/images/templates/templates-images/hero-dark.png")',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  outline: '1px solid',
                  outlineColor:
                    theme.palette.mode === 'light'
                      ? alpha('#BFCCD9', 0.5)
                      : alpha('#9CCCFC', 0.1),
                  boxShadow:
                    theme.palette.mode === 'light'
                      ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                      : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                })}>
                  <div style={{ margin: 10, textAlign: "center", padding: 15,  }}>
                    <Typography variant="h5" component="div" style={{ marginBottom: 10, }}>
                      £14.99 <Typography color="text.secondary">per month</Typography>
                    </Typography>

                    <Stack direction="column" spacing={2} divider={<Divider orientation="horizontal" flexItem/>}>
                      <Button size="small" variant="contained" style={{ background: "black", color: "white" }} onClick={() => navigate("signup?plan=b")}>Start free trial</Button>

                      <Stack spacing={2}>
                        <Typography variant="body2">
                          Email account with AI filter
                        </Typography>
                        <Typography variant="body2">
                          Important dates and times extraction
                        </Typography>
                          <Typography variant="body2">
                          Unlimited emails
                        </Typography>
                        <Typography variant="body2">
                          Incoming email tone highlighting
                        </Typography>
                        <Typography variant="body2">
                          Outgoing email tone analysis
                        </Typography>
                      </Stack>
                    </Stack>             
                  </div>
              </Box>
            </Grid>
          </Grid>
          


          {/* <Grid container columns={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={2} sm={1}>

              <Card style={{ margin: 10, textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Basic
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                  </Typography>
                  <Typography variant="body2">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>

            </Grid>

            <Grid item xs={2} sm={1}>

              <Card style={{ margin: 10, textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Basic
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                  </Typography>
                  <Typography variant="body2">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid> */}
          
      </Container>
    </Box>
  );
}