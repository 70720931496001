import * as React from 'react';
import { AppBar, Button, Container, Grid, Stack, TextField, Toolbar, Typography, alpha } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Hero from '../components/Hero';
import { DemoEmailView } from '../components/DemoView';
import axios from 'axios';

function appBarLabel(label: string) {
  return (
    <Toolbar variant='dense' style={{ boxShadow: "" }}>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}
export default function Signup() {
  const [email, setEmail] = React.useState("");
  return (
    <Box
      id="hero"
    >
      <Container
        style={{paddingTop: 25,}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >

        <Box
          id="image"
          style={{ marginTop: 40, height: 265 }}
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            padding: 5,
            justifyContent: "center",
            height: { xs: 500, sm: 400 },
            width: '80%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}>

          <Stack style={{ justifyContent: "center", padding: 10, marginTop: 70, }} spacing={2}>
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(1.5rem, 10vw, 1.5rem)',
              }}
            >
              Thanks!
            </Typography>
            <Typography
              textAlign="center"
              color="text.secondary"
              sx={{ alignSelf: 'center', width: { sm: '100%', md: '100%' } }}
            >
              Stay tuned for updates and early access
            </Typography>
          </Stack>
            
        </Box>
          
      </Container>
    </Box>
  );
}