import * as React from 'react';
import { Grid, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DemoEmailView } from './DemoView';
import { Link, useNavigate } from 'react-router-dom';

const A = "Junior said he is going to your sister’s house this weekend to play with cousins. You know I dont like your sister! Besides the fact that your sister is an awful parent and Junior leaves those visits behaving horribly,what gives you the right to not consult me about this?. Just because we are divorced it doesnt mean I dont get a say. You don’t get to control everything. Did it even occur to you that maybe I already made plans for Junior?? Cancel plans with your sister. You had no right to make them in the first place. Oh and stop telling people I dont give you money you knowmy situation, you're such an evil fucking witch! I will drop Junior back Tomorrow 4pm. Let me know if are you still going to Turkey on the 18th may. But be honest if you keep on fucking about like this I wont let you go anywhere!"
const B = "Junior is going to your sister’s house this weekend to play with cousins, but I disapprove due to her poor parenting and Junior's behavior after visits. Despite our divorce, I believe I should have a say in such decisions and cancel these plans as I might have already scheduled activities for Junior. Additionally, I'll drop Junior back tomorrow at 4 pm; let me know if you are still planning on going to Turkey on May 18th."

const subText = "Want to cut through emails from an abusive co-parent? Tired of unnecessary and energy sapping long emails? Allow AI to sit in the middle instead and get the information you need!"

export default function Hero() {
  const navigate = useNavigate();

  return (
    <Box
      id="hero"
    >
      <Container
        style={{paddingTop: 25,}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h4"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 2.5rem)',
            }}
          >
            Eradicating the unnecessary for you
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            { subText }
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="inherit" onClick={() => window.location.href = "#pricing"} style={{ background: "black", color: "white" }}>
              Pricing plans
            </Button>
          </Stack>
        </Stack>
        <Box
          id="image"
          style={{ marginTop: 40, height: "100%"}}
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 600 },
            width: '80%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}>

          <Grid container columns={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={2} sm={1}>
              <DemoEmailView text={A} header={"From emails like this..."}/>
            </Grid>
            <Grid item xs={2} sm={1}>
            <DemoEmailView text={B} header={"To emails like this!"}/>
            </Grid>
          </Grid>
              
            
          </Box>
          
      </Container>
    </Box>
  );
}