import axios from "axios";

export type Email = {
  to: string;
  from: string;
  subject: string;
  text: string;
  html: string;
};

export const sendEmail = async (email: Email) => {
  console.log('sending email', email);

  try {
    const result = await fetch(
      "https://europe-west1-spectext-2e9fb.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...email
        })
      });
      console.log("result", result)
    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

  // const fd = new FormData();
  // const result = await axios.post(
  //   'https://postal.coparentmail.com/api/v1/send/message',
  //   {
  //     body: {
  //       to: [email.to],
  //       from: email.from,
  //       subject: email.subject,
  //       plain_body: email.text,
  //       html_body: email.html
  //     },
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json',
  //       "X-Server-API-Key": "aO9JkqRjegokbwmlOUY4MK8a"
  //     },
  //     adapter: "fetch"
      
  //   }

  // );


